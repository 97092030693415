<template>
  <div class="products-detail-container">
    <div class="top-products">
      <span>Top 3 Ürün</span>
      <div class="items">
        <ProductItem
          v-for="(item, index) in toP3SkuList"
          :key="index"
          :item="item"
          :index="index"
        />
      </div>
    </div>
    <div class="strategic-products">
      <span>Stratejik Ürünler</span>
      <div class="items">
        <ProductItem
          v-for="(item, index) in strategicSkuList"
          :key="index"
          :item="item"
          :index="index"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, toRefs } from 'vue';
import ProductItem from './ProductItem.vue';

export default defineComponent({
  components: { ProductItem },
  props: {
    toP3SkuList: {
      type: Array,
      required: true,
    },
    strategicSkuList: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const { toP3SkuList, strategicSkuList } = toRefs(props);

    return {
      toP3SkuList,
      strategicSkuList,
    };
  },
});
</script>

<style scoped lang="scss">
.products-detail-container {
  height: 300px;
  width: 100%;
  max-width: 830px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0 50px 0;
}

.top-products,
.strategic-products {
  background-color: #f8f9f9;
  border: 1px solid #ececec;
  border-radius: 5px;
  padding: 20px;
}

.top-products {
  width: 58%;
  span {
    font-size: 16px;
    font-weight: 500;
  }
}

.strategic-products {
  width: 40%;
  span {
    font-size: 16px;
    font-weight: 500;
  }
}

.items {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
</style>
